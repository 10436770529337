import {
  parseISO,
  format,
  add,
  formatISO,
  isDate,
  formatRelative,
  startOfDay,
  endOfDay,
  addMinutes,
  set,
  formatDuration,
} from 'date-fns'
import { toZonedTime, fromZonedTime, formatInTimeZone } from 'date-fns-tz'
import {
  format as formatNoTZ,
  differenceInCalendarDays,
  formatDistanceToNow,
} from 'date-fns'
import { todayStoreTimeZone, toStoreTimeZone } from '~common/utils/timezone'

import { RsvDow } from '~common/generated/admin-graphql'
import { enUS } from 'date-fns/locale/en-US'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-require-imports
// const { enUS } = require('date-fns/locale/en-US') // keep is as require remix doesn't support common js imports

export const dow = [
  RsvDow.Mon,
  RsvDow.Tue,
  RsvDow.Wed,
  RsvDow.Thu,
  RsvDow.Fri,
  RsvDow.Sat,
  RsvDow.Sun,
]

const convert = (date: Date | string) => {
  return typeof date === 'string' ? new Date(date) : date
}
export const timeFormat = (date: Date | string) => {
  return format(convert(date), 'h:mmaaa')
}

export const dateFormat = (date: Date | string) => {
  return format(convert(date), 'yyyy-MM-dd')
}

export const dateFormatUs = (date: Date | string) => {
  return format(convert(date), 'MM-dd-yyyy')
}

export const dayFormat = (date: Date | string) => {
  return format(convert(date), 'EEEE')
}

export const prettyDateFormat = (
  date: Date | string,
  isCompact?: boolean,
  includeTime?: boolean,
) => {
  const time = includeTime ? ' h:mmaaa' : ''
  return isCompact
    ? format(convert(date), `MMM d, yyyy${time}`)
    : format(convert(date), `EEE, MMM d, yyyy${time}`)
}

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
  const date = new Date(dateString)
  return date.toLocaleString('en-US', options)
}

export const userDateFormat = (date: Date | string) => {
  return format(convert(date), 'MMM dd, yyyy')
}
/**
 * parses date string to timezone relative date object
 */
export const parseISODateString = (
  isoDateString: string,
  timezone?: string,
): Date => {
  if (timezone) {
    return toZonedTime(isoDateString, timezone)
  }
  return parseISO(isoDateString)
}

export const isValidTimeZone = (tz: string) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return true
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz })
    return true
  } catch (ex) {
    return false
  }
}

export const parseISODateToUTC = (
  isoDateString: string,
  timezone?: string,
  toLocal?: boolean,
) => {
  let date
  if (timezone) {
    if (toLocal) {
      return toZonedTime(isoDateString, timezone)
    }
    return toZonedTime(isoDateString, timezone, { timeZone: timezone })
  } else {
    date = parseISO(isoDateString)
  }

  const localTimezoneOffset = date.getTimezoneOffset()

  return add(date, { minutes: localTimezoneOffset })
}

export const formatISODateTime = (date: string | Date): string => {
  if (isDate(date)) {
    return formatISO(date)
  }
  return formatISO(parseISODateString(date))
}

const calcZonedDate = (
  date: string | number | Date,
  tz: string,
  fn: any,
  options: any = null,
) => {
  const inputZoned = toZonedTime(date, tz)
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned)
  return fromZonedTime(fnZoned, tz)
}

export const getZonedStartOfDay = (
  date: string | number | Date,
  timeZone: string,
) => {
  return calcZonedDate(date, timeZone, startOfDay)
}

export const getZonedEndOfDay = (
  date: string | number | Date,
  timeZone: string,
) => {
  return calcZonedDate(date, timeZone, endOfDay)
}

export const relativeTime = (date: string | number | Date) => {
  return formatRelative(date, new Date())
}

export const getClassTime = (
  time: string,
  duration: number,
  timezone?: string,
) => {
  const currentTime = parseISODateToUTC(`2023-01-01T${time}`, timezone)
  const laterTime = addMinutes(currentTime, duration)
  return `${format(currentTime, 'h:mmaaa')} - ${format(laterTime, 'h:mmaaa')}`
}

export const getOffsetName = (time: string, timezone: string) => {
  const currentTime = parseISODateToUTC(`2023-01-01T${time}`, timezone)
  return formatInTimeZone(currentTime, timezone, 'zzz', {
    locale: enUS,
  })
}

export const convertNumbersToDates = (selectableDays: number[]) => {
  return selectableDays.map((dayOfTheMonth: number) => {
    return set(new Date(), {
      month: 7, // July will allow you to select 31 days
      hours: 13,
      date: dayOfTheMonth,
    })
  })
}

export const hourFormat = (timeInMinutes: number): string => {
  const days = Math.floor(timeInMinutes / (60 * 24))
  const remainingMinutesAfterDays = timeInMinutes % (60 * 24)

  const hours = Math.floor(remainingMinutesAfterDays / 60)
  const minutes = remainingMinutesAfterDays % 60

  const duration: Record<string, number> = {}
  if (days > 0) duration.days = days
  if (hours > 0) duration.hours = hours
  if (minutes > 0) duration.minutes = minutes

  return formatDuration(duration)
}

export const formatToStoreDate = (
  inputDateString: string,
  format = 'MM/dd/yyyy HH:mm:ss',
  abbreviate = false,
): string => {
  const inputDate = toStoreTimeZone(inputDateString)
  const currentDate = todayStoreTimeZone()

  const daysDifference = differenceInCalendarDays(currentDate, inputDate)
  if (abbreviate && daysDifference > 1) {
    const distance = formatDistanceToNow(inputDate, { addSuffix: true })

    return distance
  } else {
    return formatNoTZ(inputDate, format)
  }
}
