import { toZonedTime } from 'date-fns-tz'

import { getCookie } from './cookie'
import { isValidTimeZone } from './dateHelper'

export const TIMEZONE_COOKIE = 'vendure_remix_timezone'

export const toStoreTimeZone = (
  date: Date | string | number,
  cookieName = TIMEZONE_COOKIE,
) => {
  const timezone = getStoreTimeZone(cookieName)
  return toZonedTime(date, timezone)
}

export const todayStoreTimeZone = (cookieName = TIMEZONE_COOKIE) => {
  const timezone = getStoreTimeZone(cookieName)
  return toZonedTime(new Date(), timezone)
}

export const getStoreTimeZone = (cookieName = TIMEZONE_COOKIE) => {
  const timezone = 'America/Chicago'
  let cookieTimezone = timezone
  const ck = getCookie(cookieName)
  if (ck) {
    try {
      const data = JSON.parse(ck)
      if (data.timezone) {
        cookieTimezone = data.timezone
      }
    } catch (error) {
      // do nothing
      console.log('Error getting store timezone', error)
    }
  }
  const resolvedTimezone = cookieTimezone ?? timezone
  const isValid = isValidTimeZone(resolvedTimezone)
  return isValid ? resolvedTimezone : timezone
}
